import React from 'react';
import Icon from '../../static/images/icons/arrow1.svg';

const JoinUsBtn = props => {
  const { label = 'JOIN US', className = '' } = props;
  return (
    <div className={`${className} arrow-btn-container`}>
      <div className={`arrow-button`} onClick={props.onClick}>
        <div className="text">{label}</div>
        <span className="icon-container">
          <Icon className="icon"></Icon>
        </span>
      </div>
    </div>
  );
};

export default JoinUsBtn;
