import { graphql, navigate } from 'gatsby';
import React from 'react';
import LogoIcon from '../../static/images/icons/logo.svg';
import JoinUsBtn from '../components/JoinUs';
import Layout from '../components/Layout';
import Title from '../components/Title';

const Basic = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  return (
    <Layout bodyClass="page-default-single page-about">
      <Title>
        <h1>{title}</h1>
      </Title>
      <div className="container container-md container-sm mb-2 flex-grow-1">
        <div className="row justify-content-start gap-4 gap-md-6 flex-wrap flex-md-nowrap gap-sm-6">
          <div className="col-12 col-md-6 order-2 order-md-1 justify-content-center justify-content-md-end d-flex">
            <LogoIcon className="logo-icon"></LogoIcon>
          </div>
          <div className="col-12 col-md-6 order-1 order-md-2 d-flex flex-column gap-4 gap-md-6  align-items-center align-items-md-start">
            <div className="content p-mb-0" dangerouslySetInnerHTML={{ __html: html }} />
            <JoinUsBtn
              onClick={() => {
                navigate(data.configJson.url.career_url);
              }}
            ></JoinUsBtn>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      fields {
        slug
      }
      html
    }
    configJson {
      url {
        career_url
      }
    }
  }
`;

export default Basic;
